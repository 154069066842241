import React from "react"
import { Container, Row, Col, Image } from "react-bootstrap"
import Header from "../components/header"
import Footer from "../components/footer"
import Carousel from "../components/carouselJobs"
import ContactBox from "../components/boxContact"
import "../styles/jobs.css"

import imgBescheinigung from "../assets/leistungen/image.jpg"
import imgFreistellung from "../assets/leistungen/image.jpg"

import pdfBescheinigung from "../assets/leistungen/image.jpg"
import pdfFreistellung from "../assets/leistungen/image.jpg"

export default () => (
  <div>
    <Header />
    <Carousel />
    <div className="contentbox white">
      <Container className="text-center">
        <h1>Downloads</h1>
        <hr />
        <Row className="show-grid text-left">
          <Col sm={12} md={true}>
            <a href={pdfBescheinigung}>
              <Image src={imgBescheinigung} fluid />
            </a>
          </Col>
          <Col sm={12} md={true}>
            <a href={pdfFreistellung}>
              <Image src={imgFreistellung} fluid />
            </a>
          </Col>
        </Row>
      </Container>
    </div>
    <ContactBox />
    <div className="arrow-up black" />
    <div className="contentbox black foot fit" />
    <Footer />
  </div>
)
